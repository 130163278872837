export default function registerCountedShare($, apower) {
  //global share registration, used on many coms
  $.registerDir("bind-share", {
    selector: "[data-share], .apower-counted-share",

    props: [
      "share",
      "share-url",
      "share-title",
      "align",
      "gap",
      "use-apower-qrcode",
    ],

    share: "",

    shareUrl: "",

    shareTitle: "",

    align: "r",

    gap: 10,

    init: function () {
      var re = new RegExp(Object.keys(this.links).join("|"));
      var name;
      this.el.$on(
        "click",
        function (e) {
          name = this.share;

          if (!name) {
            var li = $(e.target).closest("li")[0];

            if (li) {
              name = li.className.select(re, 0);
            }
          }

          if (name) {
            this.shareTo(name, this.shareUrl, this.shareTitle, "", li);
          }
        },
        this
      );

      this.el.find("li").$on(
        "mouseenter",
        function (e) {
          var li = $(e.target).closest("li")[0];

          if (li) {
            name = li.className.select(re, 0);
          }

          if (name && this.links[name].img) {
            this.shareTo(name, this.shareUrl, this.shareTitle, "", li);
          }
        },
        this
      );
    },

    links: {
      google: {
        url: "https://plus.google.com/share?url={url}&t={title}",
        width: 500,
        height: 500,
      },

      facebook: {
        url: "http://www.facebook.com/sharer/sharer.php?u={url}&title={title}",
        width: 673,
        height: 374,
      },

      twitter: {
        url: "https://twitter.com/intent/tweet?url={url}&text={title}",
        width: 548,
        height: 430,
      },

      pinterest: {
        url: "//assets.pinterest.com/js/pinmarklet.js",
        append: true,
        width: 600,
        height: 400,
      },

      hatena: {
        url: "http://b.hatena.ne.jp/entry/panel/?url={url}&btitle={title}",
        width: 365,
        height: 220,
      },

      linkedin: {
        url: "https://www.linkedin.com/shareArticle?mini=true&url={url}&title={title}",
        width: 600,
        height: 600,
      },

      weixin: {
        img: "/do.php?action=qrcode&data={url}&size=5",
      },

      douban: {
        url: "http://www.douban.com/share/service?href={url}&name={title}&text={text}",
      },

      qzone: {
        url: "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url={url}&title={title}&desc={text}&summary=&site=",
      },

      weibo: {
        url: "http://service.weibo.com/share/share.php?url={url}&title={title}&searchPic=true",
      },

      tencent: {
        url: "http://share.v.t.qq.com/index.php?c=share&a=index&url={url}&title={title}",
      },

      youdao: {
        url: "http://note.youdao.com/memory/?url={url}&title={title}",
      },
    },

    getShareLink: function (name, url, title, text) {
      var link = this.links[name].url || this.links[name].img;

      if (name === "weixin") {
        link = apower.getDomain("zh") + link;
      }

      var o = {
        url: encodeURIComponent(url),
        title: encodeURIComponent(title),
        text: encodeURIComponent(text),
      };

      link = link.format(o);

      return link;
    },

    onopenparams: null,

    shareTo: function (name, url, title, text, btn) {
      var $btn = $(btn);

      url = url || location.href;
      title = title || $btn.data("share-title") || document.title;
      text = text || $btn.data("share-text") || "";

      if (typeof this.links[name] === "function") {
        this.links[name](url, title, text, $btn);
      } else {
        var link = this.getShareLink(name, url, title, text);

        if (this.links[name].append) {
          $("body").append('<script src="' + link + '"></script>');
        } else if (this.links[name].url) {
          var width = this.links[name].width;
          var height = this.links[name].height;
          var params = "";

          if (width && height) {
            params = "width=" + width + ",height=" + height;
          }

          if (this.onopenparams) {
            params = this.onopenparams(params, name);
          }

          window.open(link, "share-to-" + name, params);
        } else if (!$btn.dir("layer")) {
          var layer = $.newCom("layer", $btn, {
            align: this.align,
            gap: this.gap,
            layer: $(
              '<div class="ap-qrcode"><img src="' + link + '"></div>'
            ).appendTo("body"),
          });

          layer.showLayer();
        }
      }
    },
  });
  //counted share
  $.register("counted-share", {
    selector: ".apower-counted-share",

    ref: {
      items: "li",
      shareSpans: ".apower-share-label",
      countSpans: ".apower-share-count",
      twitterSpan: ".apower-share-twitter .apower-share-label",
    },

    props: ["share-url", "share-title"],

    shareURL: "",

    shareTitle: "",

    countWidth: 46,

    darkBg: false,

    canClose: false,

    template: function () {
      var specialShareHTML =
        '<li class="apower-share-pinterest"><i></i><span class="apower-share-label">Pin</span><span class="apower-share-count">-</span></li>';
      if (apower.lang === "ja") {
        specialShareHTML =
          '<li class="apower-share-hatena"><i></i><span class="apower-share-label">{@@share}</span><span class="apower-share-count">-</span></li>';
      }

      var closeHTML = this.canClose
        ? '<div class="apower-share-exit"></div>'
        : "";

      return (
        '<ul class="apower-counted-share" data-share-url="' +
        this.shareURL +
        '" data-share-title="' +
        this.shareTitle +
        '">' +
        '<li class="apower-share-google"><i></i><span class="apower-share-label">+1</span><span class="apower-share-count">-</span></li>' +
        '<li class="apower-share-facebook"><i></i><span class="apower-share-label">{@@share}</span><span class="apower-share-count">-</span></li>' +
        '<li class="apower-share-twitter"><i></i><span class="apower-share-label">{@@share}</span><span class="apower-share-count">-</span></li>' +
        specialShareHTML +
        '<li class="apower-share-linkedin"><i></i><span class="apower-share-label">{@@share}</span><span class="apower-share-count">-</span></li>' +
        closeHTML +
        "</ul>"
      );
    },

    init: function () {
      if (this.darkBg) {
        this.el.addClass("apower-share-darkbg");
      }

      var spanWidths = (this.spanWidths = []);
      this.shareSpans.each(function (index, span) {
        spanWidths[index] = $(span).getTextWidth() + 10;
      });

      this.items.$on("mouseenter", this.onEnterItem, this);
      this.el.$on("mouseleave", this.onLeaveEl, this);

      this.el.find(".apower-share-exit").$on(
        "click",
        function () {
          this.destroy(true);
        },
        this
      );

      apower.sharer.getShareCount(
        this.shareUrl,
        function (data) {
          for (var i in data) {
            var shareCount = data[i];
            this.el
              .find(".apower-share-" + i)
              .find(".apower-share-count")
              .text(shareCount);
          }
        },
        this
      );
    },

    onEnterItem: function (e) {
      var li = $(e.target).closest("li"),
        index = this.items.indexOf(li),
        span1 = this.shareSpans.eq(index),
        span2 = this.countSpans.eq(index),
        spanWidth = this.spanWidths[index],
        isTwitter = li.is(".apower-share-twitter"),
        countWidth = this.countWidth;

      var moreWidth = isTwitter
        ? Math.max(0, spanWidth - countWidth)
        : spanWidth;

      if (moreWidth) {
        var otherWidth =
          countWidth -
          moreWidth /
            (isTwitter ? this.items.length - 1 : this.items.length - 2);

        span1.width(spanWidth);
        span2.width(countWidth);
        this.shareSpans.not(span1).not(this.twitterSpan).width(0);
        this.countSpans.not(span2).width(otherWidth);

        if (!isTwitter) {
          this.twitterSpan.width(countWidth);
        }
      }
    },

    onLeaveEl: function () {
      var countWidth = this.countWidth;

      this.shareSpans.width(0);
      this.countSpans.width(countWidth);
      this.twitterSpan.width(countWidth);
    },
  });
}
