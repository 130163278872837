
import "@/styles/pages/single/index.css";
import registerViewer from "@/components/viewer";
import registerVideoViewer from "@/components/videoViewer";
import registerOpenVideo from "@/components/openVideo";
import registerSlider from "/framework/components/slider";
import registerFit from "@/components/fit";
import registerLearnMore from "@/components/learnMorePackUp";
import registerCountedShare from "@/components/countedShare";
import registerOurDomain from "@/components/ourDomain";
import registerCommentForm from "@/components/commentForm";
import registerPaging from "@/components/paging";
import registerFontFit from "@/components/fontFit";
          // window.installFramework = window.installFramework || Function.prototype
          installFramework(function(Vue) {
            // single
// /single.php
new Vue({
  created($, apower, LazyLoad) {
    registerOurDomain($, apower);
    $.register = $.registerComponent;
    window.apowerTrans = window.$apTranslations;
    window.apower = apower;
    apower.agents = {};
    if (!apower.getDownloadDomain) {
      apower.getDownloadDomain = function (lang) {
        lang = lang || apower.lang;

        switch (lang) {
          case "zh":
            return "https://download.apowersoft.cn";

          default:
            return "https://download.apowersoft.com";
        }
      };
    }
    //获取 wp 根目录, 用于执行一些 ajax 请求
    (apower.getWPPath = function (lang) {
      if (apower.isApowerDomain) {
        return "";
      }

      let path = apower.getRegionPath(lang) + "/wp";

      return path;
    }),
      registerFontFit($, apower);
    registerViewer($, apower);
    registerVideoViewer($, apower);
    registerOpenVideo($, apower);
    registerSlider($, apower);
    registerFit($, apower);
    registerCountedShare($, apower);
    registerLearnMore($, apower);
    registerPaging($, apower);
    registerCommentForm($, apower);

    function overwriteMobileReview() {
      $.extend($.com("mobile-review").prototype, {
        init: function () {
          var commentWin = $(".comment-win");

          this.countEl.text($(".comment-count").eq(0).text());

          // this.commentBtn.$on("click", function (e) {
          //   $.newCom("mobile-comment-form").show();
          // });

          this.countEl.$on(
            "click",
            function (e) {
              $.com("open-comment").prototype.showCommentWin();
              $(".comment-win-mask").css("bottom", this.el[0].offsetHeight);
            },
            this
          );

          apower.scroller.on(
            "down",
            function () {
              if (!commentWin.length || !commentWin.is(":visible")) {
                this.el.hide();
              }
            },
            this
          );

          apower.scroller.on(
            "up",
            function () {
              this.el.hide();
            },
            this
          );
        },
      });
    }

    overwriteMobileReview();
    function fixAvatar() {
      //str.replace('///','/').replace(/\.webp$/,'')
      let img = $(".comment-portrait img");
      if (img.length == 0) {
        return;
      }
      for (let i = 0; i < img.length; i++) {
        let item = $(img[i]);
        let str = item.attr("src") || "";
        str = str.replace("///", "/").replace(/\.webp$/, "");
        item.attr("src", str);
      }
    }
    fixAvatar();
    //auto init mobile reviews
    $.registerOnce(function () {
      if ($(window).width() <= 768) {
        $.newCom("mobile-review");
      }
    });

    //article css adjust
    $.registerOnce(function () {
      $("p .img_style1").closest("p").css("clear", "both");
    });

    //for adjust font-size  when el is white space
    $.registerOnce(
      ".unlimit-gg .title, .unlimit-gg .off, .purple-red, .learn, .get, .supreme > div",
      {
        init: function () {
          this.el.newCom("font-fit");
        },
      }
    );

    $.registerOnce(".unlimit-gg, .promotion-gg", {
      init: function () {
        this.el.$on("click", this.onClickEl, this);
      },

      onClickEl: function (e) {
        var link = $(e.currentTarget).find("a");
        var isClickedA = $(e.target).closest(link).length;

        if (!isClickedA) {
          link[0].click();
        }
      },
    });

    //for subheader
    $.registerOnce(".subheader", {
      init: function () {
        apower.scroller.on("up", this.onScrollUp, this);
        apower.scroller.on("down", this.onScrollDown, this);
      },

      onScrollUp: function () {
        this.el.stop().hide();
      },

      onScrollDown: function () {
        this.el.stop().show();

        if (window.innerWidth > 768) {
          $(".header .subnav").stop().hide();
        }
      },
    });

    $.registerOnce(".newsletter-form", {
      ref: {
        emailInput: "[name=email]",
        closeEl: ".close",
        submitEl: ".button",
      },

      init: function () {
        this.closeEl.$on("click", this.onClickClose, this);
        this.emailInput.$on("input", this.onInputFocus, this);
        this.emailInput.$on("keydown", this.onKeycode, this);
        this.emailInput.$on("change", this.onChangeInput, this);
        this.submitEl.$on("click", this.sendEmail, this);
      },

      onInputFocus: function () {
        this.emailInput.removeClass("touched");
      },

      onKeycode: function (e) {
        if (e.keyCode === 13) {
          this.sendEmail(e);
        } else {
          return;
        }
      },

      onChangeInput: function () {
        this.emailInput.addClass("touched");
      },

      onClickClose: function () {
        this.emailInput.val("");
        this.emailInput.focus();
        this.emailInput.removeClass("touched");
      },

      sendEmail: function (e) {
        e.preventDefault();

        if (!this.emailInput[0].validity.valid) {
          return;
        }

        var post = this.el.formObj({
          language: apower.lang,
        });

        this.submitEl.addClass("btn-loading");

        this.subscribe(
          "newsletter-send-email",
          post,
          function (data) {
            this.submitEl.removeClass("btn-loading");

            var errorCode = data.data.error_code;

            if (data.status == 1) {
              $.msg.success(apower.tr("subscription_sended"));
            } else if (errorCode == -1) {
              $.msg.error(apower.tr("invalid_email"));
            } else if (errorCode == -2) {
              $.msg.error(apower.tr("subscription_error"));
            } else if (errorCode == -3) {
              $.msg.error(apower.tr("subscribed_already"));
            }
          },
          this
        );
      },

      subscribe: function (action, post, onsuccess, scope) {
        $.ajax({
          type: "post",
          url: "https://support.apowersoft.com/api/support?action=" + action,
          data: post,
          dataType: "json",
          success: onsuccess.bind(scope),
        });
      },
    });

    $.registerOnce(".scroll-content", {
      init: function () {
        this.offsetTop = this.el.offset().top;

        apower.scroller.on("scroll", this.onScrollDown, this);
        $(window).$on("resize", this.onResize, this);
      },

      onScrollDown: function () {
        var left = this.el.offset().left;
        var scrollTop = $(window).scrollTop();
        var footerHeight = $(".footer")[0] ? $(".footer")[0].offsetHeight : 0;
        var height = this.el[0].offsetHeight + footerHeight + 100;
        if (
          this.offsetTop < scrollTop &&
          height + scrollTop < $("body")[0].scrollHeight
        ) {
          this.el.addClass("fixed");
          this.el.css({ left: left, top: 65 + "px" });
        } else {
          this.el.removeClass("fixed");
        }
      },

      onResize: function () {
        var mainContent = $(".main-content");
        var left = mainContent.offset().left + mainContent.width() + 50;

        this.el.css({ left: left });
      },
    });

    $.registerOnce(".subheader .comment", {
      init: function () {
        this.el.$on("click", this.onClick, this);
      },

      onClick: function () {
        var offsetTop = $(".leave-comment").offset().top - 60;

        window.scrollTo(0, offsetTop);
      },
    });

    $.registerOnce("a", {
      init: function () {
        this.el.$on("click", this.onClick, this);
      },

      onClick: function (e) {
        var linkEl = $(e.currentTarget);
        var re = new RegExp("^#.*");
        var href = linkEl.attr("href");

        if (re.test(href)) {
          var cls = href.replace("#", "");
          var el = $("#" + cls);

          if (el.length > 0) {
            this.scrollToEl(el);
          }
        }
      },

      scrollToEl: function (el) {
        var top = el.offset().top;
        var height = Math.max($(".header").height(), $(".subheader").height());
        if ($(".subheader").length || $(".subheader-new").length) {
          top = top - height - 20;
        }
        $("html,body").animate(
          {
            scrollTop: top,
          },
          100
        );
      },
    });

    //activity
    $.registerOnce(".activity-btn", {
      init: function () {
        var btn = this.el.find("#activityClose");
        btn.$on("click", this.onClose, this);
      },
      onClose: function (e) {
        $(e.currentTarget).parents(".activity-btn").hide();
      },
    });

    //new article-201812 and new faq page subheader
    $.registerComponent("subheader-new", {
      template: `
      <div class="subheader-new" data-fixed-screen>
        <div class="wrapper">
          <div class="logo"><img ref="logo" src=""/><span ref="proname"></span></div>
          <div class="offer" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
            <a class="community" ref="features" href="#">Features</a>
            <a class="community" :class.active="type==='faq'" ref="guide" href="#">Guide</a>
            <a class="community active" ref="resource" f-if="type==='single'" href="#">Resource</a>
            <a class="download" ref="download" href="#" rel="nofollow">Free Download</a>
          </div>
        </div>
      </div>
    `,
      logourl: "",
      titlename: "",
      featuresurl: "",
      featuretr: "",
      guideurl: "",
      guidetr: "",
      downloadurl: "",
      downloadid: "",
      downloadtr: "",
      resourcetr: "",
      type: "",
      init() {
        this.logo.attr("src", this.logourl);
        this.proname.text(this.titlename);
        this.features.attr("href", this.featuresurl);
        this.features.text(this.featuretr);
        this.guide.text(this.guidetr);
        if (this.type === "single") {
          this.guide.attr("href", this.guideurl);
        }
        this.download.attr("id", this.downloadid);
        this.download.attr("href", this.downloadurl);
        this.download.text(this.downloadtr);
        this.resource.text(this.resourcetr);
        apower.scroller.on("scroll", this.onScrollDown, this);
      },
      onScrollDown: function (e) {
        if (e > 50) {
          this.el.addClass("scroll-down");
        } else {
          this.el.removeClass("scroll-down");
        }
      },
    });

    //new article-201812 page and new faq scroll-content
    $.registerOnce(".scroll-content-new", {
      init: function () {
        this.offsetTop = this.el.offset().top;

        apower.scroller.on("scroll", this.onScrollDown, this);
        $(window).$on("resize", this.onResize, this);
      },

      onScrollDown: function () {
        var left = this.el.offset().left;
        var scrollTop = $(window).scrollTop();
        var footerHeight = $(".footer")[0] ? $(".footer")[0].offsetHeight : 0;
        var height = this.el[0].offsetHeight + footerHeight + 100;
        if (
          this.offsetTop < scrollTop &&
          height + scrollTop < $("body")[0].scrollHeight
        ) {
          this.el.addClass("fixed");
          this.el.css({ left: left, top: 65 + "px" });
        } else {
          this.el.removeClass("fixed");
        }
      },

      onResize: function () {
        var mainContent = $(".main-content");
        var left = mainContent.offset().left - 300;

        this.el.css({ left: left });
      },
    });
    //new article-201812 page and new faq scroll-content
    $.registerOnce(".scroll-content-new-mobile", {
      init: function () {
        this.height = this.el.height();
        this.offsetTop = this.el.offset().top + this.el.height();
        apower.scroller.on("scroll", this.onScrollDown, this);
        $(window).$on("resize", this.onResize, this);
      },

      onScrollDown: function () {
        var left = this.el.offset().left;
        var scrollTop = $(window).scrollTop();
        var footerHeight = $(".footer")[0] ? $(".footer")[0].offsetHeight : 0;
        var height = this.el[0].offsetHeight + footerHeight + 100;
        if (
          this.offsetTop < scrollTop &&
          height + scrollTop < $("body")[0].scrollHeight
        ) {
          this.el
            .parent()
            .next()
            .css("margin-top", this.height + 50 + "px");
          this.el.addClass("fixed");
          this.el.css({ left: left, top: 105 + "px" });
        } else {
          this.el.removeClass("fixed");
          this.el.parent().next().css("margin-top", "initial");
        }
      },

      onResize: function () {
        var mainContent = $(".main-content");
        var left = mainContent.offset().left - 300;

        this.el.css({ left: left });
      },
    });

    //new article-201812 page aside
    $.registerComponent("article-aside-list", {
      template: `
      <div class="article-aside-list"></div>
    `,
      init: function () {
        var article = $(".article-body");
        var patt = /^_\d*/;
        var pTitle = Array.from(article.find('h2[id^="_"]'));
        var cTitle = Array.from(article.find('h3[id^="_"]'));
        var titleArr = Array.from(article.find('h2[id^="_"],h3[id^="_"]'));
        pTitle.forEach((item, index) => {
          var pId = $(item).attr("id");
          var cNode = cTitle
            .filter((e) => $(e).attr("id").match(patt)[0] === pId)
            .map(
              (el) =>
                `<li><a href="#${$(el).attr("id")}">${$(el).text()}</a></li>`
            )
            .join("");
          var nochild = "";
          if (!cNode) {
            nochild = "nochild";
          }
          var itemNode =
            `
          <div class="list-item ${nochild}">
            <h3><a href="#${pId}">${$(item).text()}</a></h3>
            <ul>` +
            cNode +
            `</ul>
          </div>
        `;
          this.el.append(itemNode);
        });
        //click
        this.el.find("li,h3").$on("click", this.onClickLi, this);

        //scroll
        var ratingTop = $(".rating-share-section").offset().top;
        var liItem = this.el.find(".list-item");
        apower.scroller.on(
          "scroll",
          this.getScroll(titleArr, liItem, ratingTop),
          this
        );
      },
      onClickLi: function (e) {
        // $(this.el).find('.list-item').removeClass('is-active').find('li').removeClass('active')
        // $(e.currentTarget).closest('.list-item').addClass('aaaas-active')
        // if(e.currentTarget.localName === 'li'){
        // 	$(e.currentTarget).addClass('active')
        // }
        $(this.el).parent().removeClass("active");
        $(this.el).parent().next().removeClass("active");
      },
      getScroll(titleArr, liItem, ratingTop) {
        return function (e) {
          titleArr.forEach(function (item, index) {
            var itemY = $(item).offset().top - 240;
            var less = index === 0 && itemY > e,
              more = index === titleArr.length - 1 && ratingTop < e;
            if (itemY <= e) {
              var id = $(item).attr("id");
              var tagName = $(item)[0].localName === "h3" ? "li" : "h3";
              var target = $(".article-aside-list")
                .find(tagName + ' a[href="#' + id + '"]')
                .parents(tagName);
              liItem.removeClass("is-active").find("li").removeClass("active");
              if (tagName === "li") {
                target
                  .addClass("active")
                  .closest(".list-item")
                  .addClass("is-active");
              } else if (tagName === "h3") {
                target.closest(".list-item").addClass("is-active");
              }
            }
            if (less || more) {
              liItem.removeClass("is-active").find("li").removeClass("active");
            }
          });
          // $('.scroll-content-new-mobile').removeClass('active')
        };
      },
    });

    $.registerComponent("poster-container", {
      selector: ".poster-container",

      autoDestroy: true,

      expiredAfter: 1 * 60 * 60 * 1000, //1 hour

      init() {
        if (!$.store.get("poster_closed")) {
          this.el.css("display", "flex");
        } else {
          this.el.css("display", "none");
        }

        this.el.find(".close-btn").$on("click", this.onClickClose, this);
      },

      onClickClose() {
        event.stopPropagation();
        this.el.css("display", "none");
        $.store.set("poster_closed", true, this.expiredAfter);
        return false;
      },
    });

    $.registerOnce(".related-new", {
      init: function () {
        this.el
          .find(".related-item:first-child")
          .find("h3:last-of-type")
          .text("More Resource:");
      },
    });

    $.registerOnce(".scroll-menu", {
      init: function () {
        this.el.$on("click", this.onClickEl, this);
      },
      onClickEl: function (e) {
        if ($(this.el).prev().hasClass("active")) {
          $(this.el).prev().removeClass("active");
          $(this.el).removeClass("active");
        } else {
          $(this.el).prev().addClass("active");
          $(this.el).addClass("active");
        }
      },
    });
    $.registerComponent("paging", {
      pages: 0,

      currentPage: 1,

      template: `
        <div class="paging" f-show="pages > 1">
          <ul>
            <li class="paging-prev" f-enable="currentPage > 1" @click="selectPage(currentPage - 1)">Prev@@prev</li>
            <li f-for="index of getSelectablePages()" @click="selectPage(index)" :class.active="index === currentPage">{{index}}</li>
            <li class="paging-next" f-enable="currentPage < pages" @click="selectPage(currentPage + 1)">Next@@next</li>
          </ul>
        </div>
      `,

      getSelectablePages() {
        let { pages, currentPage } = this;
        let minPage = currentPage - 2;
        let maxPage = currentPage + 2;

        if (minPage < 1) {
          minPage = 1;
          maxPage = 5;
        }

        if (maxPage > pages) {
          maxPage = pages;
          minPage = Math.max(1, pages - 4);
        }

        let array = [];
        for (let i = minPage; i <= maxPage; i++) {
          array.push(i);
        }

        return array;
      },

      selectPage(index) {
        this.currentPage = index;
        this.trigger("select", index);
      },
    });
  },
});

          })
        