export default function registerFit($, apower) {
  //we want to make sure items can be auto scaled by their available sizes, it's a little like `object-fit: contain`
  //items should set width and height, and set transform origin
  //please don't use it for the elements in the first screen
  $.registerDir("fit", {
    selector: "[data-fit], .article iframe",

    props: ["fit", "fit-direction"],

    //pc or mobile
    fit: "",

    fitDirection: "both", //has h or v

    ow: 0,

    oh: 0,

    init: function () {
      $(window).$on(
        "resize",
        $.lazyThrottle(this.doFit.bind(this, false), 500),
        this
      );

      if (this.fit === "pc") {
        $.onToggleMobile(this.cancelFit, this.reFit, this);
      } else if (this.fit === "mobile") {
        $.onToggleMobile(this.reFit, this.cancelFit, this);
      }

      var parent = this.el.parent();
      while (parent[0] && parent[0] !== document.documentElement) {
        parent = parent.parent();
      }

      //we must make sure layouts have been completed
      $.defer(this.doFit.bind(this, true));
    },

    doFit: function (immediately) {
      var el = this.el;

      if (
        el[0] ===
        (document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullscreenElement ||
          document.msFullscreenElement)
      ) {
        el.css("transform", "none");
        return;
      }

      if (!this.ow) {
        this.ow = el[0].offsetWidth;
      }

      if (!this.oh) {
        this.oh = el[0].offsetHeight;
      }

      var ow = this.ow;
      var oh = this.oh;

      if (
        !ow ||
        !oh ||
        (this.fit === "pc" && window.innerWidth <= 768) ||
        (this.fit === "mobile" && window.innerWidth > 768)
      ) {
        return;
      }

      var parent = el.parent();
      var aw = parent.innerWidth();
      var ah = parent.innerHeight();

      if (!aw || !ah) {
        return;
      }

      var ml = el.cssNum("margin-left");
      var mr = el.cssNum("margin-right");
      var mt = parseFloat(el[0].style.marginTop);
      var mb = parseFloat(el[0].style.marginBottom);

      if (mt < 0 && mb === mt) {
        ah -= mt + mb;
      }

      //el may has a `auto` margin and got an numeric computed value
      if (ml !== 0 || mr !== 0) {
        var oldDisplay = el[0].style.display;
        if (oldDisplay !== "none") {
          el[0].style.display = "none";

          if (ml !== 0 && el.css("margin-left") !== "auto") {
            ow += ml;
          }

          if (mr !== 0 && el.css("margin-right") !== "auto") {
            ow += mr;
          }

          el[0].style.display = oldDisplay;
        }
      }

      var scale = 1;
      var canHFit = this.fitDirection === "h" || this.fitDirection === "both";
      var canVFit = this.fitDirection === "v" || this.fitDirection === "both";
      if ((canHFit && aw < ow) || (canVFit && ah < oh)) {
        var scaleByWidthOrHeight = aw / ow <= ah / oh;

        if ((canHFit && scaleByWidthOrHeight) || !canVFit) {
          scale = aw / ow;
        } else {
          scale = ah / oh;
        }
      }

      var value = "scale(" + scale + ")";
      var transformOrigin = el.css("transform-origin") || "";
      var transformOriginY = (transformOrigin.split(" ")[1] || "").replace(
        /px/,
        ""
      );
      transformOriginY = Number(transformOriginY) || 0;

      var setTransform = function () {
        if (scale === 1) {
          el.css("margin-bottom", "");
          el.css("margin-top", "");
          el.css("transform", "");
        } else {
          var marginTop = transformOriginY * (scale - 1);
          var marginBottom = (oh - transformOriginY) * (scale - 1);

          el.css("margin-bottom", marginBottom);
          el.css("margin-top", marginTop);
          el.css("transform", value);
        }

        this.trigger("fit", el);
      }.bind(this);

      if (immediately) {
        setTransform();
      } else {
        $.defer(setTransform);
      }
    },

    //sone elements may has different sizes in mobile devices
    reFit: function () {
      this.ow = this.el[0].offsetWidth;
      this.oh = this.el[0].offsetHeight;
      this.doFit();
    },

    cancelFit: function () {
      this.el.css("margin-bottom", 0).css("transform", "none");
    },

    onDestroy: function () {
      $(window).$off("resize", this.doFit, this);
    },
  });
}
