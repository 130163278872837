export default function registerViewer($, apower) {
  //viewer
  $.register("viewer", "win", {
    selector: ".viewer",

    template:
      '<div class="viewer">' + '<div class="viewer-body"></div>' + "</div>",

    target: "body",

    mask: true,

    ref: {
      body: ".viewer-body",
    },

    onReady: function () {
      this.maskEl.addClass("video-viewer-mask");
    },

    show: function () {
      this.el.show();

      var w = this.width,
        h = this.height;

      var dw = $(window).width(),
        dh = $(window).height();

      var aw = dw - 30,
        ah = dh - 30;

      if (aw < w) {
        h = (h * aw) / w;
        w = aw;
      }

      if (ah < h) {
        w = (w * ah) / h;
        h = ah;
      }

      this.el.width(w).height(h);
      this.body.attr("width", w).attr("height", h);
      this.body.width(w).height(h);
      this.el.addClass("active");

      $("body").addClass("body-fixed");
      this.trigger("show");
    },

    hide: function () {
      this.el.removeClass("active");
      $("body").removeClass("body-fixed");
      this.trigger("hide");

      setTimeout(
        function () {
          this.destroy();
        }.bind(this),
        300
      );
    },
  });
}
