export default function registerVideoViewer($, apower) {
  //video viewer
  $.register("video-viewer", "viewer", {
    video: "",

    poster: "",

    width: 640,

    height: 360,

    useIframe: false,

    template: function () {
      if (this.useIframe) {
        return (
          '<div class="viewer video-viewer">' +
          '<iframe class="viewer-body" src="{video}" allowfullscreen></iframe>' +
          "</div>"
        ).format(this);
      } else {
        return (
          '<div class="viewer video-viewer">' +
          '<video class="viewer-body" autoplay controls poster="{poster}" width="100%">' +
          '<source src="{video}" type="video/mp4"></source>' +
          "</video>" +
          "</div>"
        ).format(this);
      }
    },
  });
}
