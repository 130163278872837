export default function registerLearnMore($, apower) {
  //learn more and packup
  $.registerDir("learn-more", {
    selector: ".learn-more > div",

    init: function () {
      this.el.$on("click", function (e) {
        var learnEl = $(e.target).closest(".learn-more");
        var sectionEl = learnEl.closest(".function");
        var packEl = sectionEl.find(".pack-up");
        var writingEl = sectionEl.find(".writing");
        var moreEl = sectionEl.find(".more-content");
        var pEl = sectionEl.find("h2 + p");

        if ($(window).width() < 768) {
          learnEl.before(sectionEl.find(".more > *"));
        }

        learnEl.hide();
        packEl.show();
        moreEl.slideDown(300);

        if (pEl.dir("multiline-overflow")) {
          pEl.dir("multiline-overflow").cancel();
        }

        var startHeight = pEl.height();
        pEl.addClass("show-more");
        writingEl.addClass("remove-padding");
        var endHeight = pEl.height();

        pEl.height(startHeight);

        pEl.animate(
          {
            height: endHeight,
          },
          300,
          function () {
            pEl.height("");
            apower.offset.update();
          }
        );

        //move screen to top
        var screenTop = sectionEl.offset().top;
        var pageTop = $("html").scrollTop() || $("body").scrollTop();

        if (screenTop > pageTop) {
          $("html, body").animate(
            {
              scrollTop: screenTop,
            },
            300,
            "swing"
          );
        }
      });
    },
  });

  //learn more and packup
  $.registerDir("pack-up", {
    selector: ".pack-up > div",

    init: function () {
      if (this.el.closest(".pack-up").css("display") == "block") {
        var pEa = this.el.closest(".function").find(".writing p");
        pEa.addClass("show-more");
      }

      this.el.$on("click", function (e) {
        var packEl = $(e.target).closest(".pack-up");
        var sectionEl = packEl.closest(".function");
        var moreEl = sectionEl.find(".more-content");
        var writingEl = sectionEl.find(".writing");
        var pEl = sectionEl.find("h2 + p");
        var learnEl = sectionEl.find(".learn-more");
        var scrollTop = $("html").scrollTop() || $("body").scrollTop();

        var startHeight = pEl.height();
        pEl.removeClass("show-more");
        writingEl.removeClass("remove-padding");
        var endHeight = pEl.height();

        if (moreEl.length && moreEl.offset().top < scrollTop) {
          var reduceHeight = moreEl.height() + startHeight - endHeight;

          var newTop = scrollTop - reduceHeight - 50;
          $("html, body").animate(
            {
              scrollTop: newTop,
            },
            300
          );
        }

        learnEl.show();
        packEl.hide();
        moreEl.slideUp(300);

        pEl.height(startHeight);

        pEl.animate(
          {
            height: endHeight,
          },
          300,
          function () {
            pEl.height("");
            apower.offset.update();

            if (pEl.dir("multiline-overflow")) {
              pEl.dir("multiline-overflow").updateEllipsis();
            }
          }
        );
      });
    },
  });
}
