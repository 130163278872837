export default function registerOurDomain($, apower) {
  //是否是我们的管理的域名
  let isApowerDomain = location.host.includes("apowersoft");
  let isOurDomain = false;
  if (isApowerDomain) {
    isOurDomain = true;
  } else {
    if (
      location.host.includes("airmore") ||
      location.host.includes("showmore") ||
      location.host.includes("videograbber") ||
      location.host.includes("screenshot") ||
      location.host.includes("lightpdf") ||
      location.host.includes("lightmake") ||
      location.host.includes("reccloud")
    ) {
      isOurDomain = true;
    }
  }
  apower.isOurDomain = isOurDomain;
}
