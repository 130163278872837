export default function registerOpenVideo($, apower) {
  $.registerDir("open-video", {
    selector: "[data-video], .fancybox-media",

    ref: {
      orgVideo: "video",
    },

    props: ["video", "video-width", "video-height"],

    videoWidth: 640,

    videoHeight: 360,

    video: "",

    skipClass: "",

    init: function () {
      this.video = this.video || this.el.attr("href");

      if (/youtube\.com\/watch\?v=[\w-]+/.test(this.video)) {
        this.video = this.video.replace(
          /watch\?v=([\w-]+)(&?)/,
          function (m0, m1, m2) {
            return "embed/" + m1 + (m2 ? "?" : "");
          }
        );
      }

      if (/youtube\.com/.test(this.video)) {
        if (this.video.includes("?")) {
          if (!this.video.includes("autoplay=1")) {
            this.video += "&autoplay=1";
          }
        } else {
          this.video += "?autoplay=1";
        }
      }

      this.el.$on("click", this.onClickEl, this);
    },

    onClickEl: function (e) {
      e.preventDefault();

      if ($(window).width() <= 768) {
        open(this.video);
        return;
      }

      if (this.skipClass && $(e.target).closest(this.skipClass).length) {
        return;
      }

      var video = this.el.find("video");
      var useIframe = false;

      if (video.length) {
        this.video = this.video || video[0].currentSrc;
        this.poster = video[0].poster || "";
        video.trigger("pause");
      } else if (this.video.startsWith("http")) {
        useIframe = true;
      }

      $.newCom("video-viewer", {
        video: this.video,
        poster: this.poster,
        width: this.videoWidth,
        height: this.videoHeight,
        useIframe: useIframe,
      }).show();
    },
  });
}
