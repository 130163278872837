export default function registerCommentForm($, apower) {
  let tr = apower.tr;
  //comment-form
  $.register("comment-form", {
    selector: ".comment-form",

    ref: {
      inputs: "input, textarea",
      submitEl: ".submit",
      cancelEl: ".cancel-reply",
      parentIdEl: '[name="comment_parent"]',
      authorEl: "[name=author]",
      emailEl: "[name=email]",
      contentEl: "[name=comment]",
    },

    init: function () {
      this.leaveCommentEl = this.el.closest(".leave-comment");
      this.commentEl = this.leaveCommentEl.siblings(".comment");
      this.initForm();
      this.initValue();
    },

    initForm: function () {
      this.inputs.$on("input", function (e) {
        if (e.target.value) {
          $(e.target).addClass("touched");
        } else {
          $(e.target).removeClass("touched");
        }
      });

      this.inputs.$on("focus", function (e) {
        $(e.target).parent().addClass("active");
      });

      this.inputs.$on("blur", function (e) {
        var input = $(e.target);
        if (!input.val()) {
          input.parent().removeClass("active");
        }
      });

      this.contentEl.$on(
        "keydown",
        function (e) {
          if (e.keyCode === 13) {
            e.preventDefault();

            if (this.el.checkValidity()) {
              this.doSubmit();
            }
          }
        },
        this
      );

      this.submitEl.$on(
        "click",
        function (e) {
          e.preventDefault();

          if (this.el.checkValidity()) {
            this.doSubmit();
          }
        },
        this
      );

      this.cancelEl.$on(
        "click",
        function (e) {
          e.preventDefault();
          this.inputs.blur().removeClass("touched");
          this.el.appendTo(this.leaveCommentEl);
          this.parentIdEl.val("0");
          this.commentEl
            .find(".comment-replying")
            .removeClass("comment-replying");
        },
        this
      );
    },

    initValue: function () {
      if (this.authorEl.val() === "Anonymous") {
        this.authorEl.val("");
      }

      if (this.emailEl.val() === "anonymous@apowersoft.com") {
        this.emailEl.val("");
      }

      this.contentEl.val("");
    },

    setParentId: function (id) {
      this.parentIdEl.val(id);
    },

    doSubmit: function () {
      var url = this.el.attr("action");
      if (url.indexOf("/wp/") == -1) {
        url = `/wp${url}`;
      }
      $.ajax({
        type: "POST",
        url: $.nocache(url),
        // $.nocache(apower.getWPPath() + "/wp-admin/admin-ajax.php")
        data: this.el.formData(),
        processData: false,
        contentType: false,
        dataType: "text",
        success: this.onSubmitted.bind(this),
        error: this.onSubmitError.bind(this),
      });

      this.contentEl.blur().attr("disabled", "disabled");
      this.submitEl.addClass("btn-loading");
    },

    onSubmitted: function (data) {
      this.contentEl.blur().removeAttr("disabled");
      this.submitEl.removeClass("btn-loading");

      if (typeof data === "string") {
        var errorMsg = data.select(/^\s*<p>(.+?)<\/p>\s*$/, 1);
        if (errorMsg) {
          $.msg.alert(errorMsg);
          return;
        }

        try {
          data = JSON.parse(data);
        } catch (e) {
          data = {};
        }
      }

      var author = this.authorEl.val();
      var content = this.contentEl.val();
      var commentItem = this.el.closest(".comment-item");
      commentItem = commentItem.length ? commentItem : null;

      data.comment_author = data.comment_author || author || tr("anonymous");
      data.comment_content = data.comment_content || content;
      data.comment_date = data.comment_date || $.formatDate(new Date());

      $(".comment").each(function (index, comment) {
        $(comment).com().onNewComment(data, commentItem);
      });

      this.initValue();
      this.inputs.removeClass("touched").parent().removeClass("active");

      this.trigger("submitted");
    },

    onSubmitError: function (xhr) {
      this.contentEl.blur().removeAttr("disabled");
      this.submitEl.removeClass("btn-loading");

      var errorMsg = (xhr.responseText || "").select(/<p>(.+?)<\/p>/, 1);
      $.msg.alert(errorMsg || tr("submit_failed"));
    },

    commentNumberPlus: function () {
      var commentCount = Number($(".comment-number span").eq(0).text()) || 0;
      commentCount++;

      $(".comment-count").text("(" + commentCount + ")");
      $(".comment-number span").text(commentCount);
    },
  });

  //comment
  $.register("comment", {
    selector: ".comment",

    ref: {
      inputs: "input",
      listEl: " > ul",
      loadMoreEl: ".load-more",
    },

    data: {},

    props: ["use-ago-time"],

    page: 0,

    paging: 5,

    pageCount: 1,

    pagingMode: false,

    useAgoTime: false,

    init: function () {
      this.loadMoreMode = !!this.loadMoreEl.length;

      this.form = this.el.siblings(".leave-comment").find(".comment-form");
      this.pagingMode =
        !this.el.closest(".comment-win").length && !this.loadMoreMode;

      this.initPermlink();
      this.initPageInfo();

      if (this.useAgoTime) {
        this.initPublishedTime();
      }

      //article page
      if (this.pagingMode) {
        this.initPaging();
      }
      //article click right button to open comment win, or open it in product page
      else if (this.loadMoreMode) {
        this.initLoadMore();
      } else {
        this.initWin();
      }

      this.el.$on("click", this.onClickLike, this);
      this.el.$on("click", this.onClickReply, this);
    },

    initPermlink: function () {
      if (/comment=\d+/i.test(location.search)) {
        var commentId = location.search.match(/comment=(\d+)/i)[1];
        var commentEl = $("#comment-" + commentId);
        if (commentEl.length) {
          location.hash = "#comment-" + commentId;
        }
      }
    },

    onClickLike: function (e) {
      var btn = $(e.target).closest(".comment-like, .comment-dislike");
      var isLike = btn.is(".comment-like");
      var twoBtns = btn.parent().children(".comment-like, .comment-dislike");

      if (!btn.length) {
        return;
      }

      twoBtns.addClass("comment-liked");
      var id = btn.closest("[data-id]").data("id");

      var post = {
        comment_ID: id,
        vote_type: isLike ? "1" : "-1",
        action: "wc_vote_via_ajax",
      };

      $.ajax({
        type: "POST",
        url: $.nocache(apower.getWPPath() + "/wp-admin/admin-ajax.php"),
        data: post,
        dataType: "json",
        success: function (body) {
          if (body.code == 1) {
            btn.text((Number(btn.text()) || 0) + 1);
          } else if (body.code == -1) {
            twoBtns.removeClass("comment-liked");
          }
        },
      });
    },

    onClickReply: function (e) {
      var btn = $(e.target).closest(".comment-reply");

      if (!btn.length) {
        return;
      }

      var form = this.form;

      var id = btn.closest("[data-id]").data("id");
      var selfEl = btn.closest(".comment-self");

      form.com().setParentId(id);

      if ($(window).width() > 768) {
        btn.closest(".comment-actions").before(form);
        this.el.find(".comment-replying").removeClass("comment-replying");
        selfEl.addClass("comment-replying");
      } else {
        $.newCom("mobile-comment-form").show();
      }
    },

    initPageInfo: function () {
      this.pagingWrap = this.el.find(".paging");
      this.prevEl = this.pagingWrap.find(".prev");
      this.nextEl = this.pagingWrap.find(".next");
      this.page = Math.max(
        parseInt(this.pagingWrap.find("li.active").text()) - 1 || 0,
        0
      );
      this.pageCount = parseInt(this.pagingWrap.data("page-count")) || 1;

      //the listEl may be not exist
      if (!this.listEl.length) {
        this.listEl = $('<ul id="comment-list"></ul>');
        this.el.find("h2").after(this.listEl);
      }

      this.data[this.page] = this.listEl.children();
    },

    initPaging: function () {
      this.loadEl = $(
        '<div class="loading" hidden><div><div></div></div></div>'
      ).appendTo(this.el);

      this.pagingWrap.$on(
        "click",
        function (e) {
          var li = $(e.target).closest("li");
          if (!li.length) {
            return;
          }

          var newPage = 0;

          if (li.is(".prev")) {
            newPage = this.page - 1;
          } else if (li.is(".next")) {
            newPage = this.page + 1;
          } else {
            newPage = Number(li.text()) - 1;
          }

          this.loadPageData(newPage);
        },
        this
      );
    },
    nextPage() {},
    initPublishedTime: function () {
      this.el.find(".comment-time span").each(
        function (index, el) {
          $(el).text(apower.getAgoTypeTime($(el).text()));
        }.bind(this)
      );
    },

    initWin: function () {
      this.win = this.el.closest(".comment-win").com();

      this.win.on(
        "ready",
        function () {
          this.win.maskEl.addClass("comment-win-mask");

          this.loadEl = $(
            '<div class="loading-more"><div></div></div>'
          ).appendTo(this.win.body);

          if (this.page !== 0) {
            this.loadPageData(0);
          }

          this.win.body.$on("scroll", this.onBodyScroll, this);
        },
        this
      );
    },

    initLoadMore: function () {
      if (this.pageCount == 1) {
        this.loadMoreEl.hide();
      }

      this.loadMoreEl.find("> div").$on("click", this.onClickLoadMore, this);
    },

    onClickLoadMore: function () {
      this.loadPageData(this.page + 1);
    },

    onBodyScroll: function () {
      var body = this.win.body;

      if (
        this.page < this.pageCount - 1 &&
        !this.loadEl.hasClass("active") &&
        body[0].scrollTop >= body[0].scrollHeight - body[0].clientHeight
      ) {
        this.loadPageData(this.page + 1);
      }
    },

    loadPageData: function (page) {
      if (this.data[page]) {
        this.page = page;
        this.onLoadData(this.data[page]);
      } else {
        var post = {
          post_id: $('[name="comment_post_ID"]').val(),
          action: "wc_load_more_comments",
          offset: page * this.paging,
          per_page: this.paging,
        };

        this.inputs.map(function (index, input) {
          post[$(input).attr("name")] = $(input).val();
        });

        if (this.pagingMode) {
          this.loadEl.show();
        } else if (this.loadMoreMode) {
          this.loadMoreEl.addClass("active");
        } else {
          this.loadEl.addClass("active");
        }

        $.ajax({
          type: "POST",
          url: apower.getWPPath() + "/wp-admin/admin-ajax.php",
          data: post,
          dataType: "json",
          success: function (body) {
            if (this.pagingMode) {
              this.loadEl.hide();
            } else if (this.loadMoreMode) {
              this.loadMoreEl.removeClass("active");

              if (this.loadMoreEl.com()) {
                this.loadMoreEl.com().stop();
              }

              if (!body.comments || body.comments.length < this.paging) {
                this.loadMoreEl.hide();
              }
            } else {
              this.loadEl.removeClass("active");
            }

            this.page = page;
            this.pageCount =
              Math.ceil(body.parent_count / this.paging) || this.pageCount;
            this.data[page] = body.comments;
            this.onLoadData(body.comments);
          }.bind(this),
        });
      }
    },

    generateHTML: function (list) {
      if (!list) {
        return "";
      }

      var a = [];

      for (var i = 0; i < list.length; i++) {
        var json = list[i];
        var isMyComment =
          apower.user && apower.user.getId() == json.comment_uid;
        var avatar = isMyComment
          ? apower.user.getAvatar()
          : json.avatar || json.avatar_url;
        var portraitClass = avatar ? " has-portrait" : "";
        var commentDate = this.useAgoTime
          ? apower.getAgoTypeTime(json.comment_date || json.comment_time)
          : json.comment_date;
        var author = json.comment_author || json.nickname;
        var html = (
          '<li class="comment-item" data-id="{comment_ID}" id="comment-{comment_ID}">' +
          '<div class="comment-self" itemprop="comment" itemscope itemtype="http://schema.org/Comment">' +
          '<div class="comment-portrait' +
          portraitClass +
          '">' +
          '<img src="' +
          avatar +
          '">' +
          "</div>" +
          '<div class="comment-author" itemprop="author">' +
          author +
          "</div>" +
          '<a class="comment-time" itemprop="datePublished" href="?comment={comment_ID}">' +
          commentDate +
          "</a>" +
          '<div class="comment-text" itemprop="text">{comment_content}</div>' +
          '<div class="comment-actions">' +
          '<div class="comment-like" itemprop="upvoteCount">{like}</div>' +
          '<div class="comment-dislike" itemprop="downvoteCount">{dislike}</div>' +
          '<div class="comment-reply-count">{child_count}</div>' +
          '<div class="comment-reply">' +
          tr("reply") +
          "</div>" +
          "</div>" +
          "</div>" +
          '<ul class="comment-reply-items">' +
          "{child}" +
          "</ul>" +
          "</li>"
        )
          .format(json)
          .format({
            child: this.generateHTML(json._child),
            child_count: json._child ? json._child.length : 0,
          });

        a.push(html);
      }

      return a.join("");
    },

    onLoadData: function (list) {
      this.el.find(".cancel-reply").click(); //may has no this element

      if (this.pagingMode) {
        this.listEl.empty();

        var page = this.page,
          paging = this.paging,
          pageCount = this.pageCount;

        var pageStart = Math.max(0, page - Math.floor((paging - 1) / 2)),
          pageEnd = Math.min(pageCount, pageStart + paging);

        if (pageEnd === pageCount) {
          pageStart = Math.max(0, pageEnd - paging);
        }

        this.pagingWrap.find("li").not(".prev, .next").remove();

        for (var i = pageStart; i < pageEnd; i++) {
          var li = $("<li>" + (i + 1) + "</li>");

          if (i === page) {
            li.addClass("active");
          }

          this.nextEl.before(li);
        }

        if (page > 0) {
          this.prevEl.removeAttr("disabled");
        } else {
          this.prevEl.attr("disabled", "disabled");
        }

        if (page < pageCount - 1) {
          this.nextEl.removeAttr("disabled");
        } else {
          this.nextEl.attr("disabled", "disabled");
        }
      }
      let item;
      if (Array.isArray(list)) {
        item = $(this.generateHTML(list)).appendTo(this.listEl);
      } else {
        item = list.clone().appendTo(this.listEl);
      }
    },

    onNewComment: function (json, parentCommentItem) {
      var needReview = apower.pageType !== "video";

      var awaitTemplate = "";
      if (needReview) {
        awaitTemplate =
          '<div class="comment-await">' + tr("success_to_review") + "</div>";
      }

      var actionTemplate = "";
      if (json.comment_id) {
        actionTemplate = apower.trans(
          '<div class="comment-actions">' +
            '<div class="comment-like" itemprop="upvoteCount">0</div>' +
            '<div class="comment-dislike" itemprop="downvoteCount">0</div>' +
            '<div class="comment-reply-count">0</div>' +
            '<div class="comment-reply">{@@reply}</div>' +
            "</div>"
        );
      } else {
        json.comment_id = Date.now();
      }

      var commentDate = this.useAgoTime
        ? apower.getAgoTypeTime(json.comment_date || json.comment_time)
        : json.comment_date;
      var portrait = apower.user ? apower.user.getAvatar() : "";
      var portraitClass = portrait ? " has-portrait" : "";

      var template =
        '<li class="comment-item" data-id="{comment_id}" id="comment-{comment_id}">' +
        '<div class="comment-self" itemprop="comment" itemscope itemtype="http://schema.org/Comment">' +
        '<div class="comment-portrait' +
        portraitClass +
        '"><img src="' +
        portrait +
        '"></div>' +
        '<div class="comment-info">' +
        '<div class="comment-author" itemprop="author">{comment_author}</div>' +
        '<a class="comment-time" itemprop="datePublished">' +
        commentDate +
        "</a>" +
        "</div>" +
        '<div class="comment-text" itemprop="text">{comment_content}</div>' +
        awaitTemplate +
        actionTemplate +
        "</div>" +
        "</li>";

      this.el.removeClass("no-comment");

      var item = $(template.format(json));

      if (parentCommentItem && !needReview) {
        var ul = parentCommentItem.find("> .comment-reply-items");
        if (!ul.length) {
          ul = $('<ul class="comment-reply-items"></ul>').appendTo(
            parentCommentItem
          );
        }
        ul.prepend(item);
      } else {
        this.listEl.prepend(item);
      }

      if (this.data[0] && this.data[0].add) {
        this.data[0] = item.add(this.data[0]);
      }
    },
  });
  //mobile comment form for article page, only on touch devices
  $.register("mobile-comment-form", {
    ref: {
      inputs: "input, textarea",
      submitEl: ".submit",
      cancelEl: ".cancel",
      loadEl: ".loading",
    },

    template:
      '<form class="mobile-comment-form" action="" method="post">' +
      '<div class="wrapper">' +
      '<div class="title">{@@comments}</div>' +
      '<textarea placeholder="{@@write_your_review}" name="comment" required maxlength="1024"></textarea>' +
      '<input type="hidden" name="author" value="Anonymous">' +
      '<input type="hidden" name="email" value="anonymous@apowersoft.com">' +
      '<div class="win-foot">' +
      '<button class="submit">{@@submit}</button>' +
      '<button class="cancel">{@@cancel}</button>' +
      "</div>" +
      "</div>" +
      '<div class="loading" hidden><div><div></div></div></div>' +
      "</form>",

    target: "body",

    init: function () {
      this.oldForm = $(".comment-form").eq(0);

      this.inputs.$on(
        "input",
        function (e) {
          if ($(e.target).val() !== "") {
            this.submitEl.addClass("active");
          } else {
            this.submitEl.removeClass("active");
          }
        },
        this
      );

      this.cancelEl.$on(
        "click",
        function (e) {
          e.preventDefault();
          this.destroy();
        },
        this
      );

      this.loadEl.$on(
        "click",
        function () {
          this.destroy();
        },
        this
      );

      this.el.$on(
        "submit",
        function (e) {
          e.preventDefault();
          this.loadEl.show();

          this.oldForm.com().inputs.each(
            function (index, input) {
              $(input).val(this.el.find("[name=" + input.name + "]").val());
            }.bind(this)
          );

          this.oldForm.com().doSubmit();
          this.oldForm.com().on("submitted", this.onSubmitted, this);
        },
        this
      );
    },

    onSubmitted: function () {
      this.destroy();
    },

    show: function () {
      this.el.show().find("textarea").focus();
    },

    onDestroy: function () {
      this.oldForm.com().off("submitted", this.onSubmitted, this);
    },
  });

  //an api to open comment window
  $.registerDir("open-comment", {
    selector: "[data-open-comment], .feature-comment",

    init: function () {
      this.el.$on(
        "click",
        function () {
          this.showCommentWin();
        },
        this
      );
    },

    showCommentWin: function () {
      if ($(".comment-win").length) {
        $(".comment-win").com().show();
      } else {
        var win = $.newCom("win");
        win.maskEl.addClass("comment-win-mask");
        win.el.addClass("comment-win");

        var comment = $(".comment");
        win.body.append($(".leave-comment").clone());
        win.body.append($(".comment").clone());
        win.body.append($(".paging").clone());
        win.body.find(".comment h2").appendTo(win.head);
        win.body
          .find(".comment .comment-form")
          .appendTo(win.body.find(".leave-comment"));
        win.body
          .find(".comment .comment-replying")
          .removeClass("comment-replying");

        var number = win.el.find(".comment-number");
        var body = win.el.find(".win-body");

        number.$on(
          "click",
          function () {
            var offsetTop = win.el.find(".comment").position().top - 150 || 0;

            body[0].scrollTo(0, offsetTop);
          },
          this
        );

        win.body.applyCom();
        win.show();
      }
    },
  });

  //bottom review for article page
  $.register("mobile-review", {
    template:
      '<div class="mobile-review">' +
      '<div class="mobile-review-wrapper">' +
      '<div class="mobile-comment-btn">{@@comments}</div>' +
      '<div class="mobile-review-btns">' +
      '<div class="mobile-rating-btn"></div>' +
      '<div class="mobile-share-btn" data-open-share></div>' +
      '<div class="mobile-comment-count"></div>' +
      "</div>" +
      "</div>" +
      "</div>",

    target: "body",

    ref: {
      countEl: ".mobile-comment-count",
      commentBtn: ".mobile-comment-btn",
    },

    init: function () {
      var commentWin = $(".comment-win");

      this.countEl.text($(".comment-count").eq(0).text());

      this.commentBtn.$on("click", function (e) {
        $.newCom("mobile-comment-form").show();
      });

      this.countEl.$on(
        "click",
        function (e) {
          $.com("open-comment").prototype.showCommentWin();
          $(".comment-win-mask").css("bottom", this.el[0].offsetHeight);
        },
        this
      );

      apower.scroller.on(
        "down",
        function () {
          if (!commentWin.length || !commentWin.is(":visible")) {
            this.el.hide();
          }
        },
        this
      );

      apower.scroller.on(
        "up",
        function () {
          this.el.show();
        },
        this
      );
    },
  });

  //popup share window
  $.register("share-win", "win", {
    props: ["share-url"],

    autoDestroy: true,

    template: function () {
      let bodyHTML;
      if (apower.lang === "zh") {
        bodyHTML =
          '<li title="分享到豆瓣" class="douban"></li>' +
          '<li title="分享到QQ空间" class="qzone"></li>' +
          '<li title="分享到新浪微博" class="weibo"></li>' +
          '<li title="复制到浏览器" class="copy-link" data-copy="' +
          location.href +
          '"></li>';
      } else {
        var shareClass = apower.lang === "ja" ? "hatena" : "pinterest";

        bodyHTML =
          '<li class="facebook"></li>' +
          '<li class="twitter"></li>' +
          '<li class="google"></li>' +
          '<li class="' +
          shareClass +
          '"></li>';
      }

      return apower
        .trans(
          '<div class="win share-win">' +
            '<h2 class="win-head">{@@share}</h2>' +
            '<ul class="win-body" data-share>' +
            "{0}" +
            "</ul>" +
            "</div>"
        )
        .format(bodyHTML);
    },

    init: function () {
      this.callSuper("win");
    },
  });

  $.registerDir("open-share", {
    selector: "[data-open-share]",

    props: ["share-url"],

    init: function () {
      this.el.$on("click", this.onClickEl, this);
    },

    onClickEl: function (e) {
      $.newCom("share-win", {
        shareURL: this.shareURL,
      }).show();
    },
  });
}
