export default function registerFontFit($, apower) {
  let tr = apower.tr;
  //scale font size to make sure it fits the container, used for white-space:nowrap elements
  $.registerDir("font-fit", {
    selector: "[data-font-fit]",

    init: function () {
      this.adjustFontSize();

      if (document.fonts) {
        document.fonts.ready.then(this.adjustFontSize.bind(this));
      } else {
        $.onWinLoaded(this.adjustFontSize, this);
      }
    },

    adjustFontSize: function () {
      this.el.resetFontSizeToBeContained();
    },
  });
}
