export default function registerPaging($, apower) {
  let tr = apower.tr;
  $.registerComponent("paging", {
    pages: 0,

    currentPage: 1,

    selector: ".paging",

    template: `
      <div class="paging" f-show="pages > 1">
        <ul>
          <li class="paging-prev" f-enable="currentPage > 1" @click="selectPage(currentPage - 1)">Prev@@prev</li>
          <li f-for="index of getSelectablePages()" @click="selectPage(index)" :class.active="index === currentPage">{{index}}</li>
          <li class="paging-next" f-enable="currentPage < pages" @click="selectPage(currentPage + 1)">Next@@next</li>
        </ul>
      </div>
    `,

    getSelectablePages() {
      let { pages, currentPage } = this;
      let minPage = currentPage - 2;
      let maxPage = currentPage + 2;

      if (minPage < 1) {
        minPage = 1;
        maxPage = 5;
      }

      if (maxPage > pages) {
        maxPage = pages;
        minPage = Math.max(1, pages - 4);
      }

      let array = [];
      for (let i = minPage; i <= maxPage; i++) {
        array.push(i);
      }

      return array;
    },

    selectPage(index) {
      console.log("selectPage");
      this.currentPage = index;
      this.trigger("select", index);
    },
    nextPage() {
      console.log("nextPage");
    },
  });
}
